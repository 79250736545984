import $ from 'jquery'
import FormAjax from './formajax'
import Util from './util'

/**
 * --------------------------------------------------------------------------
 * Bootstrap (v4.1.3): oneFilter.js
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * --------------------------------------------------------------------------
 */

const OneFilter = (($) => {
  /**
   * ------------------------------------------------------------------------
   * Constants
   * ------------------------------------------------------------------------
   */

  const NAME = 'onefilter'
  const VERSION = '4.1.3'
  const DATA_KEY = 'bs.onefilter'
  const JQUERY_NO_CONFLICT = $.fn[NAME]
  const FILTER_DELAY = 800
  const OTHER_CARDS_COUNT = 6
  const PARTNERS_CARDS_COUNT = 6
  const ENTER_KEY_CODE = 13

  const PartnersCards = {}

  const Selector = {
    INPUT_SELECT: '.inputselect',
    PARTNER_CATALOGUE_BLOCK: '.js-partners-catalogue__new_block',
    PARTNER_CATALOGUE_BLOCK_HEADER: '.partners-catalogue__new_header',
    PARTNER_CATALOGUE_BLOCK_LITTLE: '.js-partners-catalogue__new_block--little',
    PARTNER_CATALOGUE_ITEM: '.partners-catalogue__new_block--wrapper',
    PARTNER_CATALOGUE_EMPTY: '.partners-catalogue__new_empty',
    PARTNER_CATALOGUE_COUNTRIES: '*[data-country]',
    PARTNER_CATALOGUE_CATEGORIES: '*[data-category]',
    FILTER_BLOCK: '.banner-select_area',
    FILTER_SECTIONS: '#sections',
    FILTER_COUNTRY: '#country',
    FILTER_BADGES: '.filter__badges',
    FILTER_BADGES_TAG: '.filter__badges-tag',
    FILTER_BADGES_DELETE: '.filter__badges-tag--remove',
    FILTER_BADGES_RESET: '.filter__badges-button',
    SEARCH_FIELD: '.select2-search__field',
    SELECTOR_CHOICE: '.select2-selection__choice',
    SELECTOR_OPTION: '.select2-results__option',
    SELECTOR_OPTION_INPUT: '.custom-control-input--filter__categories',
    SELECTOR_TOGGLE: '.toggle',

    FIND_PARTNER_BLOCK: '.js-partners-block',
    FIND_PARTNER_ITEM: '.js-partners-item',
    FIND_PARTNER_SHOW_MORE_BTN: '.js-show-more-btn',
    FIND_PARTNER_FILTER_BTN: '.js-filter-btn',
    FIND_PARTNER_EMPTY_BLOCK: '.partners__new-emptyblock',
    FIND_PARTNER_COUNTRIES: '#countries',
    FIND_PARTNER_INDUSTRIES: '#industries',
    FIND_PARTNER_LEVEL: '#level',
    FIND_PARTNER_COMPANY: '#company',
    FIND_PARTNER_COMPANY_NAME: '.partners__new-text_title',
    FIND_PARTNER_HIDDEN_FORM: '#hidden_form'

  }

  const Event = {
    SELECT_INITED: 'select2:inited',
    FILTER_APPLY: 'filter.apply',
    FILTER_APPLY_END: 'filter.end',
    SELECT_CHANGED: 'change.select2',
    SELECT_CLOSE: 'select2:close',
    SELECT_OPEN: 'select2:open',
    SELECT_SELECTING: 'select2:selecting',
    SELECT_LOADED: 'select2:results:all',
    CLICK: 'click'
  }

  class OneFilter {
    constructor(element) {
      this._element = element
    }

    // Getters

    static get VERSION() {
      return VERSION
    }

    // Public

    filterCardsApply() {
      const $block = $(this._element)
      const $smallCards = []
      const parentCategoriesArray = []
      $block.addClass('blur')
      if ($.isEmptyObject(PartnersCards)) {
        $block.find(Selector.PARTNER_CATALOGUE_ITEM)
          .each((i, item) => {
            PartnersCards[i] = item
          })
      }
      const $empty = $(Selector.PARTNER_CATALOGUE_EMPTY)
      $empty.removeClass('hidden')
      $block.empty()
      if (!$.isEmptyObject(PartnersCards)) {
        const filterObj = Util.getURLParameters()
        if ({}.hasOwnProperty.call(filterObj, 'industry') && {}.hasOwnProperty.call(filterObj, 'country')) {
          let mainCount = 0
          $.each(PartnersCards, (i, item) => {
            const $card = $(item)
            const $categories = $card.find(Selector.PARTNER_CATALOGUE_CATEGORIES)
            const industry = $categories.map((i, item) => $(item)
              .attr('data-category'))
            $categories.each((i, item) => {
              const id = $(item)
                .attr('data-category')
              const pid = $(item)
                .attr('data-parent')
              if ($.inArray(id, filterObj.industry) !== -1 && pid !== '') {
                parentCategoriesArray.push(pid)
              }
            })
            const countries = $card.find(Selector.PARTNER_CATALOGUE_COUNTRIES)
              .map((i, item) => $(item)
                .attr('data-country'))
            const foundIndustry = Util.intersection(industry, filterObj.industry)
            const foundCountries = filterObj.country.length > 0 ? countries.filter((i, value) => value === filterObj.country) : countries
            if (foundIndustry.length > 0 && foundCountries.length > 0) {
              $block.append($card)
              mainCount++
            } else {
              $smallCards[i] = $card
            }
          })
          if (mainCount > 0) {
            $empty.addClass('hidden')
          }
          const $smallCardsBlock = $(Selector.PARTNER_CATALOGUE_BLOCK_LITTLE)
          $smallCardsBlock.empty()
          let count = 0
          $.each($smallCards, (i, item) => {
            const $card = $(item)
            const $categories = $card.find(Selector.PARTNER_CATALOGUE_CATEGORIES)
            const parents = $categories.map((i, item) => $(item)
              .attr('data-parent'))
            const foundIndustry = Util.intersection(parents, parentCategoriesArray)
            if (foundIndustry.length > 0 && count < OTHER_CARDS_COUNT) {
              $smallCardsBlock.append($card)
              count++
            }
          })
          const $header = $(Selector.PARTNER_CATALOGUE_BLOCK_HEADER)
          if (count > 0) {
            $header.removeClass('hidden')
          } else {
            $header.addClass('hidden')
          }
          $block.delay(FILTER_DELAY)
            .removeClass('blur')
            .trigger(Event.FILTER_APPLY_END)
        }
      }
    }

    filterBadgesApply() {
      const $block = $(this._element)
      const $badges = $block.find(Selector.FILTER_BADGES_TAG)
      const $resetButton = $(Selector.FILTER_BADGES_RESET)
      if ($badges.length > 0) {
        $badges.addClass('hidden')
        $resetButton.addClass('hidden')
        $block.addClass('hidden')
        let isHided = true
        const filterObj = Util.getURLParameters()
        if ({}.hasOwnProperty.call(filterObj, 'industry') && {}.hasOwnProperty.call(filterObj, 'country')) {
          $.each($badges, (i, item) => {
            const foundBadges = false
            const $badge = $(item)
            const badgeValue = $badge.attr('title')
            const foundBadgesC = filterObj.country.length > 0 ? filterObj.country === badgeValue : foundBadges
            const foundBadgesS = filterObj.industry.length > 0 ? filterObj.industry.filter((value) => value === badgeValue) : foundBadges
            if (foundBadgesC || foundBadgesS.length > 0) {
              $badge.removeClass('hidden')
              isHided = false
            }
          })
        }
        if (!isHided) {
          $block.removeClass('hidden')
          $resetButton.removeClass('hidden')
        }
      }
    }

    filterBadgesDelete() {
      const $badgeDelete = $(this._element)
      const $badge = $badgeDelete.parent()
      const badgeValue = $badge.attr('title')
      const filterObj = Util.getURLParameters()
      // eslint-disable-next-line no-nested-ternary
      const country = filterObj.country.length > 0 ? filterObj.country === badgeValue ? '' : filterObj.country : ''
      const industry = filterObj.industry.length > 0 ? filterObj.industry.filter((value) => value !== badgeValue) : []
      $badge.addClass('hidden')
      const $sections = $(Selector.FILTER_SECTIONS)
      const $countries = $(Selector.FILTER_COUNTRY)
      if (filterObj.country !== country) {
        $countries.val(country)
          .trigger('change')
      }
      if (filterObj.industry !== industry) {
        $sections.val(industry)
          .trigger('change')
        OneFilter._resetSelectorPlaceholder($sections)
      }
    }

    filterBadgesReset() {
      const $block = $(this._element)
      const $badges = $block.find(Selector.FILTER_BADGES_TAG)
      const $reset = $block.next(Selector.FILTER_BADGES_RESET)
      const $sections = $(Selector.FILTER_SECTIONS)
      const $countries = $(Selector.FILTER_COUNTRY)
      $badges.addClass('hidden')
      $reset.addClass('hidden')
      $block.addClass('hidden')
      $countries.val(null)
      $sections.val(null)
        .trigger('change')
      OneFilter._resetSelectorPlaceholder($sections)
    }

    sectionFilterCreate() {
      const $sections = $(this._element)
      const id = $sections.attr('id')
      let result = $sections.attr('data-result')
      let h = 80
      if (result) {
        result = JSON.parse(result)
      }
      const $selectResults = $(`#select2-${id}-results`)
      const $selectItems = $selectResults.find(Selector.SELECTOR_OPTION)
      let parentId
      $selectItems.each((i, item) => {
        const $item = $(item)
        const id = $item.find('input')
          .attr('id')
        h += $item.outerHeight()
        if (id in result) {
          parentId = id
          const span = $('<span>', {
            class: 'toggle',
            'data-id': id
          })
          $item.addClass('select2-results__group')
            .attr({
              role: 'group',
              'data-id': id
            })
            .append(span)
          $item.children(Selector.SELECTOR_TOGGLE)
            .addClass('open')
        } else {
          $item.attr('data-parent', parentId)
        }
      })
      $.each(result, (i, item) => {
        const $subSections = $(`${Selector.SELECTOR_OPTION}[data-parent='${item.ID}']`)
        const $selectedSubSections = $subSections.filter((i, it) => $(it)
          .attr('aria-selected') === 'true')
        if ($selectedSubSections.length === 0) {
          $subSections.hide()
          $subSections.each((i, item) => {
            h -= $(item)
              .outerHeight()
          })
          $(`${Selector.SELECTOR_OPTION}[data-id='${item.ID}']`)
            .children(Selector.SELECTOR_TOGGLE)
            .removeClass('open')
        }
      })
      $selectResults.css('max-height', () => `${h}px`)
    }

    subSectionsToggle($selectResults, click = false) {
      let $e = $(this._element)
      let h = 0
      if (!$e.hasClass('toggle')) {
        $e = $e.parents(Selector.SELECTOR_OPTION)
          .find(Selector.SELECTOR_TOGGLE)
      }
      const id = $e.attr('data-id')
      const $item = $e.parents(Selector.SELECTOR_OPTION)
      const selected = click ? null : $item.attr('aria-selected') === 'true'
      const $subSections = $(`${Selector.SELECTOR_OPTION}[data-parent='${id}']`)
      $subSections.toggle(selected)
      $subSections.each(function () {
        h += $(this)
          .outerHeight()
      })
      $e.toggleClass('open', selected)
      $selectResults.css('max-height', (i, val) => {
        const v = parseInt(val, 10)
        if ($e.hasClass('open')) {
          return `${v + h}px`
        }
        return `${v - h}px`
      })
    }

    sectionsClickHandler($sections, $selectResults) {
      const $e = $(this._element)
      const $item = $e.parents(Selector.SELECTOR_OPTION)
      const selected = $item.attr('aria-selected') === 'true'
      const val = $e.attr('data-id')
      const $input = $e.prev()
      let options = []
      let result = $sections.attr('data-result')
      if (result) {
        result = JSON.parse(result)
      }
      options = $sections.val()
      if (options === null || options === '') {
        options = []
      }
      if (!selected) {
        options.push(val.toString())
        $item.attr('aria-selected', true)
        $input.attr('checked', 'checked')
        OneFilter._selectSubSections(val, true)
        this.subSectionsToggle($selectResults)
      } else {
        options = options.filter((item) => item !== val)
        $item.attr('aria-selected', false)
        $input.attr('checked', false)
        OneFilter._selectSubSections(val, false)
        this.subSectionsToggle($selectResults)
        const parentId = $item.attr('data-parent')
        if (parentId) {
          options = options.filter((item) => item !== parentId)
          OneFilter._unselectCategory(parentId)
        }
      }
      const groupChildren = []
      if (result) {
        $.each(result, (num, item) => {
          if (val.toString() === item.ID.toString()) {
            if (item.CHILDREN) {
              $.each(item.CHILDREN, (childrenNum, childrenItem) => {
                groupChildren.push(childrenItem.ID)
              })
            }
          }
        })
      }
      for (let i = 0; i < groupChildren.length; i++) {
        let count = 0
        for (let j = 0; j < options.length; j++) {
          if (options[j].toString() === groupChildren[i].toString()) {
            count++
            if (selected) {
              options = Util.removeItem(options, options[j].toString())
            }
            break
          }
        }
        if (count === 0) {
          options.push(groupChildren[i].toString())
        }
      }
      $sections.val(options)
        .trigger(Event.SELECT_CHANGED)
      OneFilter._resetSelectorPlaceholder($sections)
    }

    findPartnersFilter(params) {
      const $item = $(this._element)
      const cardParams = JSON.parse($item.attr('data-props'))
      const foundCountries = params.country && cardParams.countries_covered ? cardParams.countries_covered.filter((value) => value === params.country) : cardParams.countries_covered
      const foundIndustries = params.industry && cardParams.industries ? cardParams.industries.filter((value) => value === params.industry) : cardParams.industries
      const foundLevel = params.level && cardParams.partner_level !== '' ? cardParams.partner_level.filter((value) => value === params.level) : cardParams.partner_level
      const foundCompany = params.company ? cardParams.company.filter((value) => value === params.company) : cardParams.company
      if (foundCountries.length > 0 && foundIndustries.length > 0 && foundLevel.length > 0 && foundCompany.length > 0) {
        $item.removeClass('hidden').slideDown()
      } else {
        $item.addClass('hidden').slideUp()
      }
    }


    // Static
    static _triggerIfFilterSetted(element) {
      const uriParams = Util.getURLParameters()
      if ({}.hasOwnProperty.call(uriParams, 'industry') && {}.hasOwnProperty.call(uriParams, 'country')) {
        element.trigger(Event.FILTER_APPLY, [uriParams.industry, uriParams.country])
      }
    }

    static _setSelect2VarFromParams(selectorsObj) {
      const params = Util.getURLParameters()
      let isChanged = false
      $.each(selectorsObj, (param, $selector) => {
        if ({}.hasOwnProperty.call(params, param) && params[param] !== '') {
          $selector.val(params[param])
          isChanged = $selector
        }
      })
      if (isChanged) {
        isChanged.trigger(Event.SELECT_CHANGED)
      }
    }

    static _resetSelectorPlaceholder(selector) {
      const placeholder = selector.attr('data-placeholder')
      $(Selector.SEARCH_FIELD)
        .attr('placeholder', placeholder)
        .css('width', 'auto')
      $(Selector.SELECTOR_CHOICE)
        .remove()
    }

    static _selectSubSections(id, isSelect) {
      const $subsections = $(`${Selector.SELECTOR_OPTION}[data-parent='${id}']`)
      $subsections.attr('aria-selected', isSelect)
      $subsections.find(Selector.SELECTOR_OPTION_INPUT)
        .attr('checked', isSelect ? 'checked' : false)
    }

    static _unselectCategory(id) {
      const $subsections = $(`${Selector.SELECTOR_OPTION}[data-id='${id}']`)
      $subsections.attr('aria-selected', false)
      $subsections.find(Selector.SELECTOR_OPTION_INPUT)
        .attr('checked', false)
    }

    static _hideCards($sections) {
      const $moreBtn = $(Selector.FIND_PARTNER_SHOW_MORE_BTN)
      const num = $sections.length
      if (num > PARTNERS_CARDS_COUNT) {
        $moreBtn.removeClass('hidden')
        $sections.each((i, item) => {
          if (i >= PARTNERS_CARDS_COUNT) {
            $(item)
              .addClass('hidden')
              .css('display', 'none')
          }
        })
      } else {
        $moreBtn.addClass('hidden')
      }
    }

    static _showMoreCards($cards) {
      const $moreBtn = $(Selector.FIND_PARTNER_SHOW_MORE_BTN)
      const $hiddenCards = $cards.filter((i, item) => $(item)
        .hasClass('hidden'))
      const num = $hiddenCards.length
      if (num !== 0) {
        $moreBtn.removeClass('hidden')
        const $doc = $('html')
        const scroll = $doc.scrollTop()
        $hiddenCards.each((i, item) => {
          const $item = $(item)
          if (i < PARTNERS_CARDS_COUNT) {
            $item.removeClass('hidden')
              .slideDown()
          }
        })
        $doc.scrollTop(scroll)
      } else {
        $moreBtn.addClass('hidden')
      }
    }

    static _showEmptyBlock($cards) {
      const $emptyBlock = $(Selector.FIND_PARTNER_EMPTY_BLOCK)
      const $hiddenForm = $(Selector.FIND_PARTNER_HIDDEN_FORM)
      const $showedCards = $cards.filter((i, item) => !$(item)
        .hasClass('hidden'))
      if ($showedCards.length > 0) {
        $emptyBlock.addClass('hidden').slideUp()
        $hiddenForm.slideUp()
      } else {
        $emptyBlock.removeClass('hidden').slideDown()
        $hiddenForm.slideDown()
        FormAjax._select2Init($hiddenForm.find(Selector.INPUT_SELECT), false)
      }
    }

    static _jQueryInterface(config, ...params) {
      return this.each(function () {
        let data = $(this)
          .data(DATA_KEY)
        const _config = typeof config === 'object' ? config : null

        if (!data) {
          data = new OneFilter(this, _config)
          $(this)
            .data(DATA_KEY, data)
        }
        if (typeof config === 'string') {
          if (typeof data[config] === 'undefined') {
            throw new TypeError(`No method named "${config}"`)
          }
          data[config](...params)
        }
      })
    }
  }

  /**
   * ------------------------------------------------------------------------
   * Data Api implementation
   * ------------------------------------------------------------------------
   */

  $(document)
    .ready(() => {
      /**
       * ------------------------------------------------------------------------
       * Partners catalogue blocks
       * ------------------------------------------------------------------------
       */
      const $partnerCatalogBlock = $(Selector.PARTNER_CATALOGUE_BLOCK)
      const $partnerCatalogBadges = $(Selector.FILTER_BADGES)
      if ($partnerCatalogBlock.length > 0 && $partnerCatalogBadges.length > 0) {
        $partnerCatalogBlock.on(Event.FILTER_APPLY, (e, industry, country) => {
          e.preventDefault()
          const varObj = {
            industry,
            country
          }
          Util.setURLParameters(varObj)
          OneFilter._jQueryInterface.call($partnerCatalogBlock, 'filterCardsApply')
          OneFilter._jQueryInterface.call($partnerCatalogBadges, 'filterBadgesApply')
          $('[data-toggle="tooltip"]')
            .tooltip()
        })
        $partnerCatalogBadges.find(Selector.FILTER_BADGES_DELETE)
          .on('click', (e) => {
            OneFilter._jQueryInterface.call($(e.target), 'filterBadgesDelete')
          })
        const $filterBadgesReset = $(Selector.FILTER_BADGES_RESET)
        $filterBadgesReset.on(Event.CLICK, (e) => {
          e.preventDefault()
          OneFilter._jQueryInterface.call($partnerCatalogBadges, 'filterBadgesReset')
        })
        OneFilter._triggerIfFilterSetted($partnerCatalogBlock)
      }

      /**
       * ------------------------------------------------------------------------
       * Partner Catalog Filter
       * ------------------------------------------------------------------------
       */
      const $sections = $(Selector.FILTER_SECTIONS)
      const $countries = $(Selector.FILTER_COUNTRY)
      const $block = $(Selector.FILTER_BLOCK)
      if ($sections.length > 0 && $countries.length > 0) {
        $countries.on(Event.SELECT_CHANGED, () => {
          $partnerCatalogBlock.trigger(Event.FILTER_APPLY, [$sections.val(), $countries.val()])
        })
          .on(Event.SELECT_INITED, () => {
            $countries.on(Event.SELECT_CHANGED, () => {
              $partnerCatalogBlock.trigger(Event.FILTER_APPLY, [$sections.val(), $countries.val()])
            })
          })
        $sections.on(Event.SELECT_CHANGED, () => {
          $partnerCatalogBlock.trigger(Event.FILTER_APPLY, [$sections.val(), $countries.val()])
        })
          .on(Event.SELECT_CLOSE, () => {
            OneFilter._resetSelectorPlaceholder($sections)
          })
          .on(Event.SELECT_LOADED, () => {
            OneFilter._jQueryInterface.call($sections, 'sectionFilterCreate')
          })
          .on(Event.SELECT_OPEN, () => {
            const id = $sections.attr('id')
            const $selectResults = $(`#select2-${id}-results`)
            $selectResults.off()
              .on(Event.CLICK, 'label', (e) => {
                e.stopPropagation()
                OneFilter._jQueryInterface.call($(e.target), 'sectionsClickHandler', $sections, $selectResults)
              })
              .on(Event.CLICK, Selector.SELECTOR_OPTION, (e) => {
                e.stopPropagation()
                OneFilter._jQueryInterface.call($(e.target), 'subSectionsToggle', $selectResults, true)
              })
            Util.scrollToSelector($block)
          })
          .on(Event.SELECT_INITED, () => {
            $sections.on(Event.SELECT_CHANGED, () => {
              $partnerCatalogBlock.trigger(Event.FILTER_APPLY, [$sections.val(), $countries.val()])
            })
            OneFilter._resetSelectorPlaceholder($sections)
          })
        OneFilter._setSelect2VarFromParams({
          industry: $sections,
          country: $countries
        })
        OneFilter._resetSelectorPlaceholder($sections)
      }
      /**
       * ------------------------------------------------------------------------
       * Find a Partner Filter
       * ------------------------------------------------------------------------
       */
      const $findPartnerBlock = $(Selector.FIND_PARTNER_BLOCK)
      if ($findPartnerBlock.length > 0) {
        const $findPartnersItems = $findPartnerBlock.find(Selector.FIND_PARTNER_ITEM)
        if ($findPartnersItems.length > 0) {
          OneFilter._hideCards($findPartnersItems)
          const $moreBtn = $(Selector.FIND_PARTNER_SHOW_MORE_BTN)
          $moreBtn.on(Event.CLICK, (e) => {
            e.preventDefault()
            OneFilter._showMoreCards($findPartnersItems)
          })
          const $filterBtn = $(Selector.FIND_PARTNER_FILTER_BTN)
          if ($filterBtn.length > 0) {
            const $country = $(Selector.FIND_PARTNER_COUNTRIES)
            const $industry = $(Selector.FIND_PARTNER_INDUSTRIES)
            const $level = $(Selector.FIND_PARTNER_LEVEL)
            const $company = $(Selector.FIND_PARTNER_COMPANY)
            if ($country.length > 0 && $industry.length > 0 && $level.length > 0 && $company.length > 0) {
              const $moreBtn = $(Selector.FIND_PARTNER_SHOW_MORE_BTN)
              $filterBtn.on(Event.CLICK, (e) => {
                e.preventDefault()
                const varObj = {
                  country: $country.val(),
                  industry: $industry.val(),
                  level: $level.val(),
                  company: $company.val()
                }
                OneFilter._jQueryInterface.call($findPartnersItems, 'findPartnersFilter', varObj)
                $moreBtn.addClass('hidden')
                OneFilter._showEmptyBlock($findPartnersItems)
              })
              $(document)
                .keypress((e) => {
                  const key = e.keyCode ? e.keyCode : e.which
                  if (key === ENTER_KEY_CODE) {
                    const varObj = {
                      country: $country.val(),
                      industry: $industry.val(),
                      level: $level.val(),
                      company: $company.val()
                    }
                    OneFilter._jQueryInterface.call($findPartnersItems, 'findPartnersFilter', varObj)
                    $moreBtn.addClass('hidden')
                    OneFilter._showEmptyBlock($findPartnersItems)
                  }
                })
            }
          }
        }
      }

      /**
       * ------------------------------------------------------------------------
       * enable tooltip
       * ------------------------------------------------------------------------
       */
      $('[data-toggle="tooltip"]')
        .tooltip()
    })

  /**
   * ------------------------------------------------------------------------
   * jQuery
   * ------------------------------------------------------------------------
   */

  $.fn[NAME] = OneFilter._jQueryInterface
  $.fn[NAME].Constructor = OneFilter
  $.fn[NAME].noConflict = function () {
    $.fn[NAME] = JQUERY_NO_CONFLICT
    return OneFilter._jQueryInterface
  }

  return OneFilter
})($)

export default OneFilter
