import 'owl.carousel'
import $ from 'jquery'
import Util from './util'

/**
 * --------------------------------------------------------------------------
 * Bootstrap (v4.1.3): oneci.js
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * --------------------------------------------------------------------------
 */

const Oneci = (($) => {
  /**
   * ------------------------------------------------------------------------
   * Constants
   * ------------------------------------------------------------------------
   */

  const NAME = 'oneci'
  const VERSION = '4.1.3'
  const DATA_KEY = 'bs.oneci'
  const EVENT_KEY = `.${DATA_KEY}`
  const JQUERY_NO_CONFLICT = $.fn[NAME]
  const SOLUTIONS_NUM = 3
  const NEWSROOM_CARDS_NUM = 6
  const SOLUTIONS_TEMPLATES = {}
  const ANIMATION_DELAY = 100
  const ANIMATION_SPEED = 200
  const COOKIE_SHOW_TIMEOUT = 1000

  const Cache = {}

  const ResponsiveVariants = {
    newsroom: {
      0: {
        items: 1,
        nav: false,
        dots: true,
        autoHeight: false
      },
      600: {
        items: 1,
        nav: false,
        dots: true,
        autoHeight: false
      },
      768: {
        items: 1,
        nav: false,
        dots: true,
        autoHeight: false
      },
      960: {
        items: 1,
        nav: true,
        dots: true,
        autoHeight: false
      },
      1200: {
        items: 1,
        nav: true,
        dots: true,
        autoHeight: false
      }
    },
    customerStories: {
      0: {
        items: 1,
        nav: true,
        dots: true,
        autoHeight: false
      },
      600: {
        items: 2,
        nav: true,
        dots: true,
        autoHeight: false
      },
      768: {
        items: 2,
        nav: true,
        dots: true,
        autoHeight: false
      },
      960: {
        items: 2,
        nav: true,
        dots: true,
        autoHeight: false
      },
      1200: {
        items: 2,
        nav: true,
        dots: true,
        autoHeight: false
      }
    },
    testimonials: {
      0: {
        items: 1,
        nav: false,
        dots: true
      },
      600: {
        items: 1,
        nav: false,
        dots: true
      },
      768: {
        items: 1
      },
      960: {
        items: 1
      },
      1200: {
        items: 1
      }
    },
    testimonialsSlider: {
      0: {
        items: 1,
        nav: false,
        dots: true
      },
      600: {
        items: 1,
        nav: false,
        dots: true
      },
      768: {
        items: 1,
        nav: false,
        dots: true
      },
      960: {
        items: 1,
        nav: true,
        dots: true
      },
      1200: {
        items: 1,
        nav: true,
        dots: true
      }
    },
    blog: {
      0: {
        items: 1
      },
      600: {
        items: 1
      },
      768: {
        items: 2
      },
      960: {
        items: 2
      },
      1200: {
        items: 3
      }
    },
    main: {
      0: {
        items: 1,
        nav: false,
        dots: true,
        loop: true,
        autoplay: true,
        autoplayHoverPause: true,
        autoHeight: false,
        autoplayTimeout: 10000
      },
      600: {
        items: 1,
        nav: false,
        dots: true,
        loop: true,
        autoplay: true,
        autoplayHoverPause: true,
        autoHeight: false,
        autoplayTimeout: 10000
      },
      1200: {
        items: 1,
        nav: false,
        dots: true,
        loop: true,
        autoplay: true,
        autoplayHoverPause: true,
        autoHeight: false,
        autoplayTimeout: 10000
      }
    },
    career: {
      0: {
        items: 1,
        nav: true,
        dots: true,
        loop: true
      },
      600: {
        items: 1,
        nav: true,
        dots: true,
        loop: true
      },
      1200: {
        items: 1,
        nav: true,
        dots: true,
        loop: true
      }
    },
    event: {
      0: {
        items: 1,
        nav: false,
        dots: true,
        loop: true
      },
      600: {
        items: 1,
        nav: false,
        dots: true,
        loop: true
      }
    }
  }

  const OwlParams = {
    nav: true,
    dots: true,
    smartSpeed: 700,
    margin: 24,
    autoHeight: true,
    navText: [
      '<img src="/local/templates/1ci/i/icons/Common/006w-left.svg" alt="" class="owl-prev_img">',
      '<img src="/local/templates/1ci/i/icons/Common/006w-right.svg" alt="" class="owl-next_img">'
    ],
    loop: false
  }

  const Selector = {
    SVG: '.inline',
    VIDEO: '.js-play-video',
    VIDEO_POPUP: '.js-popup-video',
    VIDEO_POPUP_CONTAINER: '.js-popup-video-wrapper',
    VIDEO_CONTAINER: '.js-video-container',
    VIDEO_MODAL_CONTAINER: '.video-modal',
    VIDEO_MODAL: '.js-video-modal',
    MODAL_CLOSE: '.close',
    BG_FIX: '.js-bg-fix',
    NAV_BAR: '#navbar',
    LANG_SELECT: '.js-language-select',
    LANG_DROPDOWN: '.js-language-dropdown',

    MENU: '.partners-menu',
    MOBILE_MENU: '.partners-menu_mobile-select',
    MENU_LINK: '.partners-menu_item-link',
    MENU_INNER: '.partners-menu_item-inner',
    LIST: '.partners-list',
    LIST_ITEM: '.js-partners-item',

    FILTER_BUTTON: '.filter_buttonblock--button',
    FILTER_BLOCK: '.filter_item-hidden',

    PARTNER_BUTTON_BLOCK: '.partners__new-buttonblock',
    PARTNER_BUTTON: '.partners__new-buttonblock_button',
    PARTNER_BLOCK: '.partners__new-hidesection',

    OWL: '.owl-carousel',

    BADGE_SLIDER: '.owl-carousel__badges',

    FOOTER_TITLE: '.footer_title',
    FOOTER_WRAPPER: '.footer_wrapper',

    SOLUTIONS: '.solutions-open',
    SOLUTIONS_CARDS: '.solutions-open_card',
    SOLUTIONS_CARDS_BUTTON: '.solutions-open_button',
    SOLUTIONS_CARDS_ITEMS: '.solutions-open_item--edit',

    PARTNERS_CATALOGUE_H3: '.partners-catalogue h3, .partners-catalogue__new_headline--title',

    COOKIE_BLOCK: '.cookie',
    COOKIE_CLOSE: '.close-policy',

    LOCAL_BLOCK: '.localization-shower',
    LOCAL_BLOCK_BUTTON: '#local_button',
    LOCAL_BLOCK_GLOBAL_BUTTON: '#global_button',
    LOCAL_BLOCK_CLOSE: '#close_button',

    phoneMaskContainer: '.phone__mask-container',

    NEWSROOM_BLOCK: '.newsroom__partners',
    NEWSROOM_CARD: '.newsroom__partners-card_wrapper',
    NEWSROOM_MORE_BTN: '.newsroom__media-button',

    PARTNERS_BLOCK_WRAPPER: '.partners-catalogue__new_block--wrapper',
    PARTNERS_BADGES_WRAPPER: '.partners-catalogue__new_badges__wrapper',
    PARTNERS_BADGES_BLOCK: '.partners-catalogue__new_badges',
    PARTNERS_BADGES_ARROW: '.partners-catalogue__new_badges--icon',
    PARTNERS_BADGES_ITEM: '.badge_new',
    PARTNERS_FILTER_APPLY: '.js-partners-catalogue__new_block',

    CAREER_NAV: '.career__tabs .nav-tabs',
    CAREER_SELECT: '.career__tabs .nav-select',
    CAREER_SELECT2: '.js-nav-select',
    CAREER_TAB: '.career__tabs-tab',
    CAREER_TAB_LINK: '.career__tabs-tab_vacancy__button',
    CAREER_SWITCH: '.nav-item',
    CAREER_TAB_VACANCIES: '.career__tabs-tab_vacancy--wrapper',

    MAIN_SLIDER_BG: '.bg__image',

    EVENT_FORM: '.event__form-form',
    EVENT_FORM_REGISTER_BTN: '.event__form .register__button'
  }

  const Classes = {
    VIDEO: 'embed-responsive embed-responsive-16by9',
    HIDE: 'd-none',
    SHOW: 'show',
    ACTIVE: 'active',
    MORE: 'more',
    LESS: 'less',
    IS_OPEN: 'opened'
  }

  const Event = {
    REINIT: `reinit${EVENT_KEY}`,
    OWL_END: 'end.owl.carousel',
    OWL_TRANSLATED: 'translated.owl.carousel',
    OWL_RESIZE: 'resized.owl.carousel',
    WINDOW_CHANGE: 'window.change',
    LOCAL_BLOCK_SHOWED: 'local.showed'
  }

  const Paths = {
    FLAGS: '/local/templates/1ci/i/icons/Flag/'
  }

  const Langs = [
    {
      id: 'https://www.1ci.com',
      text: 'Global | English',
      flag: 'global',
      lang: 'en'
    }, {
      id: '/es-es/',
      text: 'Latin America | Español',
      flag: 'Latin_America',
      lang: 'es'
    }, {
      id: '/tr-tr/',
      text: 'Turkiye | Türkçe',
      flag: 'Turkiye',
      lang: 'tr'
    }, {
      id: '/id-id/',
      text: 'Indonesia | Bahasa',
      flag: 'Indonesia',
      lang: 'id'
    }, {
      id: '/de-de/',
      text: 'Germany | Deutsch',
      flag: 'Germany',
      lang: 'de'
    }, {
      id: '/it-it/',
      text: 'Italy | Italiano',
      flag: 'Italy',
      lang: 'it'
    }, {
      id: '/ro-ro/',
      text: 'Romania | Română',
      flag: 'Romania',
      lang: 'ro'
    }
  ]

  /**
   * ------------------------------------------------------------------------
   * Class Definition
   * ------------------------------------------------------------------------
   */

  class Oneci {
    constructor(element) {
      this._element = element
    }

    // Getters

    static get VERSION() {
      return VERSION
    }

    // Public

    cookiePolicy() {
      const $block = $(this._element)
      $block.slideUp('500')
      const CookieDate = new Date()
      CookieDate.setFullYear(CookieDate.getFullYear() + 1)
      Util.setCookie('cookie_agree', true, {
        expires: CookieDate.toUTCString()
      })
    }

    gotoLocal() {
      const $block = $(this._element)
      const code = $block.attr('data-code')
      const link = $block.attr('data-link')
      const CookieDate = new Date()
      if (code !== 'undefined') {
        CookieDate.setFullYear(CookieDate.getFullYear() + 1)
        Util.setCookie('country_selected', code, {
          expires: CookieDate.toUTCString()
        })
      }
      if (link !== 'undefined') {
        window.location.href = link
      }
    }

    gotoGlobal() {
      const $block = $(this._element)
      $block.slideUp('500')
      const CookieDate = new Date()
      CookieDate.setFullYear(CookieDate.getFullYear() + 1)
      Util.setCookie('country_selected', 'EN', {
        expires: CookieDate.toUTCString()
      })
    }

    cookiePolicyShow() {
      const $block = $(this._element)
      $block.delay(COOKIE_SHOW_TIMEOUT)
        .slideDown('500')
    }

    imgToSvg() {
      const $img = $(this._element)
      const src = $img.attr('src')

      // fill cache by src with promise
      if (!Cache[src]) {
        const d = $.Deferred()
        $.get(src, (data) => {
          d.resolve($(data)
            .find('svg'))
        })
        Cache[src] = d.promise()
      }

      // replace img with svg when cached promise resolves
      Cache[src].then((svg) => {
        const $svg = $(svg)
          .clone()

        if ($img.attr('id')) {
          $svg.attr('id', $img.attr('id'))
        }
        if ($img.attr('class')) {
          $svg.attr('class', $img.attr('class'))
        }
        if ($img.attr('style')) {
          $svg.attr('style', $img.attr('style'))
        }

        if ($img.attr('width')) {
          $svg.attr('width', $img.attr('width'))
          if (!$img.attr('height')) {
            $svg.removeAttr('height')
          }
        }
        if ($img.attr('height')) {
          $svg.attr('height', $img.attr('height'))
          if (!$img.attr('width')) {
            $svg.removeAttr('width')
          }
        }

        $svg.insertAfter($img)
        $img.trigger(EVENT_KEY, $svg[0])
        $img.remove()
      })
    }

    fixBg() {
      const $banner = $(this._element)
      const bgStyle = $banner.css('background-image')
      if (bgStyle.length > 0) {
        const regExString = new RegExp(/(-webkit-image-set\(url|url)\((["'])(.*)"?\)( |%20)1x/)
        const m = regExString.exec(bgStyle)
        if (m !== null && m[3].length > 0) {
          $banner.css('background-image', `url('${m[3].replace('"', '')}')`)
        }
      }
    }

    videoLink() {
      const $video = $(this._element)
      const link = $video.attr('href')
      const $videoContainer = $video.parents(Selector.VIDEO_CONTAINER)
      if (link.length > 0 && $videoContainer.length > 0) {
        $videoContainer.addClass(Classes.VIDEO)
        $videoContainer.html(`<iframe class="embed-responsive-item" src="${link}" allow="autoplay; encrypted-media"></iframe>`)
      }
    }

    videoPopupLink() {
      const $video = $(this._element)
      const link = $video.attr('href')
      const $videoContainer = $(this._element).closest('section').find(Selector.VIDEO_MODAL)
      if (link.length > 0 && $videoContainer.length > 0) {
        $videoContainer.addClass(Classes.VIDEO)
        $videoContainer.html(`<iframe class="embed-responsive-item" src="${link}" allow="autoplay; encrypted-media"></iframe>`)
      }
    }

    menuList() {
      const $menuItem = $(this._element)
      const sectionId = $menuItem.attr('data-id')
      const $menu = $(Selector.MENU)
      const $list = $(Selector.LIST)

      $menu.find(Selector.MENU_INNER)
        .removeClass(Classes.ACTIVE)

      $menuItem.parents(Selector.MENU_INNER)
        .addClass(Classes.ACTIVE)

      const $items = $list.find(Selector.LIST_ITEM)

      $items.each(function () {
        const $this = $(this)
        const itemId = $this.attr('data-section')
        if (sectionId === itemId || sectionId === '0') {
          $this.removeClass(Classes.HIDE)
        } else {
          $this.addClass(Classes.HIDE)
        }
      })
    }

    solutionHide($element) {
      if (!Util.isEditMode()) {
        let $cards
        if ($element) {
          $cards = $element
        } else {
          $cards = $(this._element)
        }
        $cards.each(function () {
          const $this = $(this)
          const $items = $this.find(Selector.SOLUTIONS_CARDS_ITEMS)
          const $button = $this.find(Selector.SOLUTIONS_CARDS_BUTTON)
          $this.removeClass(Classes.IS_OPEN)
          if ($items.length > SOLUTIONS_NUM) {
            const $filteredItems = $items.filter((elem) => elem >= SOLUTIONS_NUM)
            $filteredItems.each((i, e) => {
              $(e)
                .delay(i * ANIMATION_DELAY)
                .hide(ANIMATION_SPEED)
            })
              .promise()
              .done(() => {
                $button.removeClass(Classes.LESS)
                  .addClass(Classes.MORE)
                  .html(SOLUTIONS_TEMPLATES.more)
              })
          } else {
            $button.hide(ANIMATION_SPEED)
          }
        })
      }
    }

    setSolutionsTemplates() {
      const $section = $(this._element)
      const data = $section.data()
      SOLUTIONS_TEMPLATES.more = data.more || null
      SOLUTIONS_TEMPLATES.less = data.less || null
      $section.find(Selector.SOLUTIONS_CARDS_BUTTON)
        .html(SOLUTIONS_TEMPLATES.more)
    }

    solutionOpen() {
      const $button = $(this._element)
      const isOpened = $button.hasClass(Classes.LESS)
      const $parentCard = $button.parents(Selector.SOLUTIONS_CARDS)
      if (!isOpened) {
        this.solutionHide($(Selector.SOLUTIONS_CARDS))
        $button.removeClass(Classes.MORE)
          .addClass(Classes.LESS)
        const $items = $button.parent()
          .prev()
          .find(Selector.SOLUTIONS_CARDS_ITEMS)
        $items.each((i, e) => {
          $(e)
            .delay(i * ANIMATION_DELAY)
            .show(ANIMATION_SPEED)
        })
          .promise()
          .done(() => {
            $button.removeClass(Classes.MORE)
              .addClass(Classes.LESS)
              .html(SOLUTIONS_TEMPLATES.less)
            $parentCard.addClass(Classes.IS_OPEN)
          })
      } else {
        this.solutionHide($parentCard)
      }
    }

    listenerDOTS() {
      const $item = $(this._element)
      const $image = $item.find('.owl-item.active .slide-image')
      const $mainDots = $item.find('.owl-dots')
      if (Util.getViewport() === 'xs') {
        const mainDotsHeight = $image.height()
        $mainDots.css('bottom', mainDotsHeight)
      } else {
        $mainDots.css('bottom', '')
      }
    }


    dispose() {
      $(this._element)
        .off(EVENT_KEY)
      $.removeData(this._element, DATA_KEY)
      this._element = null
    }

    // Static


    static _templateSelect(state) {
      if (!state.id) {
        return state.text
      }
      const out = `<span><img src="${Paths.FLAGS}${state.flag}.svg" class="language-select_flag" alt="${state.text}">${state.text}</span>`
      return $(out)
    }

    static _getCarouselParams(owl) {
      const params = Object.assign({}, OwlParams)
      const data = owl.data()
      for (const prop in data) {
        if ({}.hasOwnProperty.call(data, prop)) {
          params[prop] = data[prop]
        }
      }
      params.responsive = typeof params.responsive !== 'object' ? ResponsiveVariants[params.responsive] : params.responsive
      return params
    }

    static _getLocalBlock() {
      const selectedCountry = Util.getCookie('country_selected')
      if (!selectedCountry && Util.currentLang() === 'en') {
        $.get('/api/v1/geo/country')
          .done((data) => {
            if (data) {
              $('main')
                .prepend(data)
              const $localBlock = $(Selector.LOCAL_BLOCK)
              const $localButton = $localBlock.find(Selector.LOCAL_BLOCK_BUTTON)
              const $globalButton = $localBlock.find(Selector.LOCAL_BLOCK_GLOBAL_BUTTON)
              const $closeButton = $localBlock.find(Selector.LOCAL_BLOCK_CLOSE)
              $localBlock.find(Selector.SVG)
                .trigger(Event.REINIT)
              $localBlock.delay(COOKIE_SHOW_TIMEOUT)
                .slideDown('500', () => {
                  $(document)
                    .trigger(Event.LOCAL_BLOCK_SHOWED)
                })
              $localButton.on('click', (e) => {
                e.preventDefault()
                Oneci._jQueryInterface.call($localBlock, 'gotoLocal')
              })
              $globalButton.on('click', (e) => {
                e.preventDefault()
                Oneci._jQueryInterface.call($localBlock, 'gotoGlobal')
              })
              $closeButton.on('click', (e) => {
                e.preventDefault()
                Oneci._jQueryInterface.call($localBlock, 'gotoGlobal')
              })
            }
          })
          .fail((e) => {
            // eslint-disable-next-line no-magic-numbers
            if (e.status === 404 || e.status === 400) {
              const CookieDate = new Date()
              CookieDate.setMonth(CookieDate.getMonth() + 1)
              Util.setCookie('country_selected', 'EN', {
                expires: CookieDate.toUTCString()
              })
            }
          })
      }
    }

    static _newsroomShowMoreBtn($block) {
      const $cards = $block.find(Selector.NEWSROOM_CARD)
      const $moreBtn = $block.find(Selector.NEWSROOM_MORE_BTN)
      const count = $cards.length
      if (count > NEWSROOM_CARDS_NUM) {
        $cards.each((i, item) => {
          if (i >= NEWSROOM_CARDS_NUM) {
            $(item)
              .hide()
          }
        })
        $moreBtn.show()
      }
    }

    static _partnerCatalogueBadges($badgeWrapper) {
      if ($badgeWrapper) {
        $badgeWrapper.each(function () {
          const $this = $(this)
          const $badgesBlock = $this.find(Selector.PARTNERS_BADGES_BLOCK)
          const $badgeArrow = $this.find(Selector.PARTNERS_BADGES_ARROW)
          const $badges = $badgesBlock.children(Selector.PARTNERS_BADGES_ITEM)
          Oneci._partnerCatalogueSum($badges, $badgesBlock, $badgeArrow)
        })
      }
    }

    static _partnerCatalogueSum($badges, $badgesBlock, $badgeArrow) {
      let sum = 0
      $badges.css('white-space', 'nowrap')
      $badges.each(function () {
        sum += $(this)
          .outerWidth(true)
      })
      const halfBlock = $(Selector.PARTNERS_BLOCK_WRAPPER).css('max-width') !== '100%'
      if (sum > $badgesBlock.width() && halfBlock) {
        $badges.css('white-space', 'normal')
        if (halfBlock) {
          $badgeArrow.css('display', 'flex')
        }
        if (Util.isMobile() || Util.isTablet()) {
          $badgesBlock.click(() => {
            if ($badgesBlock.hasClass('popup')) {
              $badgesBlock.removeClass('popup')
              $badgeArrow.removeClass('open')
            } else {
              $badgesBlock.mouseleave(() => {
                $badgesBlock.removeClass('popup')
                $badgeArrow.removeClass('open')
              })
              $badgesBlock.addClass('popup')
              $badgeArrow.addClass('open')
            }
          })
        }
        if (Util.isDesktop()) {
          $badgesBlock.addClass('popup')
          $badgeArrow.addClass('open')
        }
      } else {
        $badgeArrow.css('display', 'none')
      }
    }

    static _newsroomShowMore($block) {
      const $cards = $block.find(Selector.NEWSROOM_CARD)
      const $moreBtn = $block.find(Selector.NEWSROOM_MORE_BTN)
      const $doc = $('html')
      const scroll = $doc.scrollTop()
      $cards.show('slow')
      $moreBtn.hide()
      $doc.scrollTop(scroll)
    }

    static _jQueryInterface(config) {
      return this.each(function () {
        let data = $(this)
          .data(DATA_KEY)
        const _config = typeof config === 'object' ? config : null

        if (!data && /destroy|hide/.test(config)) {
          return
        }

        if (!data) {
          data = new Oneci(this, _config)
          $(this)
            .data(DATA_KEY, data)
        }
        if (typeof config === 'string') {
          if (typeof data[config] === 'undefined') {
            throw new TypeError(`No method named "${config}"`)
          }
          data[config]()
        }
      })
    }
  }

  /**
   * ------------------------------------------------------------------------
   * Data Api implementation
   * ------------------------------------------------------------------------
   */

  $(document)
    // eslint-disable-next-line complexity
    .ready(() => {
      /**
       * ------------------------------------------------------------------------
       * Show Local block
       * ------------------------------------------------------------------------
       */
      Oneci._getLocalBlock()
      /**
       * ------------------------------------------------------------------------
       * Animation Scroll
       * ------------------------------------------------------------------------
       */
      $('a[href*="#"]:not(.nav-link):not(.tab-control)')
        .on('click', function (e) {
          const anchor = $(this)
            .attr('href')
            .split('#')
          const urlCheck = $(this)[0].href
          if (urlCheck.split('#')[0] === $(location)
            .attr('href') || urlCheck === $(location)
            .attr('href')) {
            if (anchor[1].length > 1) {
              const $target = $(`#${anchor[1]}`)
              if ($target.length > 0) {
                e.preventDefault()
                $('html, body')
                  .stop()
                  .animate({
                    scrollTop: $target.offset().top
                  }, '500')
              }
            }
          }
        })
      /**
       * ------------------------------------------------------------------------
       * creates inline svg
       * ------------------------------------------------------------------------
       */
      const $svg = $(Selector.SVG)
      if ($svg.length > 0) {
        Oneci._jQueryInterface.call($svg, 'imgToSvg')
      }
      $(document)
        .on(Event.REINIT, Selector.SVG, function () {
          Oneci._jQueryInterface.call($(this), 'imgToSvg')
        })
      /**
       * ------------------------------------------------------------------------
       * removes bug in bitrix image uploader
       * ------------------------------------------------------------------------
       */
      const $images = $('img[srcset="undefined 2x"]')
      if ($images.length > 0) {
        $images.removeAttr('srcset')
      }
      /**
       * ------------------------------------------------------------------------
       * Video links
       * ------------------------------------------------------------------------
       */
      const $video = $(Selector.VIDEO)
      if ($video.length > 0) {
        $video.on('click', (e) => {
          e.preventDefault()
          Oneci._jQueryInterface.call($video, 'videoLink')
        })
      }
      /**
       * ------------------------------------------------------------------------
       * Video popups
       * ------------------------------------------------------------------------
       */
      const videoPopup = $(Selector.VIDEO_POPUP)
      if (videoPopup.length > 0) {
        videoPopup.on('click', function (e) {
          e.preventDefault()
          const $modal = $(this).closest('.block-wrapper').find(Selector.VIDEO_MODAL_CONTAINER)
          const $close = $modal.find(Selector.MODAL_CLOSE)
          Oneci._jQueryInterface.call($(this), 'videoPopupLink')
          if ($modal.length > 0) {
            $modal.modal('show')
          }
          $close.on('click', () => {
            $(Selector.VIDEO_MODAL)
              .html('')
            $modal.modal('hide')
          })
          $modal.on('click', () => {
            $(Selector.VIDEO_MODAL)
              .html('')
            $modal.modal('hide')
          })
        })
      }

      const videoPopupWr = $(Selector.VIDEO_POPUP_CONTAINER).find('a[target="_popup"]')
      if (videoPopupWr.length > 0) {
        videoPopupWr.on('click', function (e) {
          e.preventDefault()
          const $modal = $(this).closest('.block-wrapper').find(Selector.VIDEO_MODAL_CONTAINER)
          const $close = $modal.find(Selector.MODAL_CLOSE)
          Oneci._jQueryInterface.call($(this), 'videoPopupLink')
          if ($modal.length > 0) {
            $modal.modal('show')
          }
          $close.on('click', () => {
            $(Selector.VIDEO_MODAL)
              .html('')
            $modal.modal('hide')
          })
          $modal.on('click', () => {
            $(Selector.VIDEO_MODAL)
              .html('')
            $modal.modal('hide')
          })
        })
      }
      /**
       * ------------------------------------------------------------------------
       * Fix banners bg
       * ------------------------------------------------------------------------
       */
      const $banner = $(Selector.BG_FIX)
      if ($banner.length > 0) {
        Oneci._jQueryInterface.call($banner, 'fixBg')
      }
      /**
       * ------------------------------------------------------------------------
       * Language selectors
       * ------------------------------------------------------------------------
       */
      const $langSelect = $(Selector.LANG_SELECT)
      const $langDropdown = $(Selector.LANG_DROPDOWN)
      const currentLang = Util.currentLang()
      if ($langSelect.length > 0 && $langDropdown.length > 0) {
        $langSelect.each(function () {
          const $this = $(this)
          const visible = $this.parent()
            .css('display')
          if (visible !== 'none') {
            $this.select2({
              data: Langs,
              width: '100%',
              minimumResultsForSearch: Infinity,
              dropdownParent: $(Selector.NAV_BAR),
              templateSelection: Oneci._templateSelect,
              templateResult: Oneci._templateSelect
            })
              .on('select2:select', (e) => {
                const data = e.params.data
                const link = data.id
                if (link.length > 0) {
                  window.location.href = link
                }
              })
            Langs.forEach((i) => {
              if (i.lang === currentLang) {
                $this.val(i.id)
                  .trigger('change')
              }
            })
          } else {
            $this.remove()
          }
        })
        const langDropdownVisible = $langDropdown.parent()
          .css('display')
        if (langDropdownVisible !== 'none') {
          Langs.forEach((i, count) => {
            const selected = i.lang === currentLang ? ' selected' : ''
            $langDropdown.append(`<a class="dropdown-item lang${selected}" href="${i.id}"><img src="${Paths.FLAGS}${i.flag}.svg" class="language-dropdown_flag" alt="${i.text}">${i.text}</a>`)
            if (count === 0) {
              $langDropdown.append('<div class="dropdown-divider"></div>')
            }
          })
        } else {
          $langDropdown.remove()
        }
      }
      /**
       * ------------------------------------------------------------------------
       * partners list old version. Delete after update @Find a partner@ page!
       * ------------------------------------------------------------------------
       */
      const $menu = $(Selector.MENU)
      const $list = $(Selector.LIST)
      const $mobileMenu = $(Selector.MOBILE_MENU)
      if ($menu.length > 0 && $list.length > 0 && $mobileMenu.length > 0) {
        const $links = $menu.find(Selector.MENU_LINK)
        const dataArray = []
        $links.each(function () {
          const $this = $(this)
          dataArray.push({
            id: $this.attr('data-id'),
            text: $this.text()
          })
        })
        $mobileMenu.select2({
          data: dataArray,
          theme: 'mobile',
          minimumResultsForSearch: Infinity,
          width: '100%',
          placeholder: 'Select Region'
        })
        $mobileMenu.val(0)
          .trigger('change')
        $links.on('click', function (e) {
          e.preventDefault()
          Oneci._jQueryInterface.call($(this), 'menuList')
        })

        $mobileMenu.on('select2:select', (e) => {
          const data = e.params.data.id
          $mobileMenu.attr('data-id', data)
          Oneci._jQueryInterface.call($mobileMenu, 'menuList')
        })
      }
      /**
       * ------------------------------------------------------------------------
       * OWL Carousel
       * ------------------------------------------------------------------------
       */
      const $owl = $(Selector.OWL)
      if ($owl.length > 0 && !Util.isEditMode()) {
        $owl.each(function () {
          const $this = $(this)
          const params = Oneci._getCarouselParams($this)
          const listeners = {}.hasOwnProperty.call(params, 'listeners') ? params.listeners : false
          if (listeners) {
            listeners.forEach((listener) => {
              $this.on(`${Event.OWL_END} ${Event.OWL_TRANSLATED} ${Event.OWL_RESIZE}`, () => {
                Oneci._jQueryInterface.call($this, `listener${listener.toUpperCase()}`)
              })
            })
          }
          $this.owlCarousel(params)
            .trigger(Event.OWL_END)
        })
      }
      /**
       * ------------------------------------------------------------------------
       * Badges Partner Catalogue
       * ------------------------------------------------------------------------
       */
      const $badgeWrapper = $(Selector.PARTNERS_BADGES_WRAPPER)
      const $filterApply = $(Selector.PARTNERS_FILTER_APPLY)
      if ($badgeWrapper.length > 0) {
        Oneci._partnerCatalogueBadges($badgeWrapper)
        $filterApply.on('filter.end', () => {
          Oneci._partnerCatalogueBadges($badgeWrapper)
        })
      }
      /**
       * ------------------------------------------------------------------------
       * Filter on @Find a partner@ page
       * ------------------------------------------------------------------------
       */
      const $openFilterMore = $(Selector.FILTER_BUTTON)
      const $filterHideBlock = $(Selector.FILTER_BLOCK)
      if ($openFilterMore.length > 0) {
        $openFilterMore.click(() => {
          $filterHideBlock.slideToggle('500')
          return false
        })
      }
      /**
       * ------------------------------------------------------------------------
       * Partners list
       * ------------------------------------------------------------------------
       */
      const $openBtn = $(Selector.PARTNER_BUTTON_BLOCK)
      const $hideBlock = $(Selector.PARTNER_BLOCK)
      if ($openBtn.length > 0 && $hideBlock.length > 0) {
        $openBtn.click(function () {
          const $this = $(this)
          const $button = $this.find(Selector.PARTNER_BUTTON)
          const buttonText = $button.attr('data-alttext')
          const currentText = $button.html()
          const el = $this.prev($hideBlock)
          if (el.is(':visible')) {
            el.slideUp('500')
            $button.removeClass(Classes.LESS)
              .addClass(Classes.MORE)
          } else {
            el.slideDown('500')
            $button.removeClass(Classes.MORE)
              .addClass(Classes.LESS)
          }
          $button.html(buttonText)
            .attr('data-alttext', currentText)
          return false
        })
      }
      /**
       * ------------------------------------------------------------------------
       * Solutions Features
       * ------------------------------------------------------------------------
       */
      const $solutions = $(Selector.SOLUTIONS)
      if ($solutions.length > 0) {
        Oneci._jQueryInterface.call($solutions, 'setSolutionsTemplates')
        const $solutionsCards = $(Selector.SOLUTIONS_CARDS)
        if ($solutionsCards.length > 0) {
          Oneci._jQueryInterface.call($solutionsCards, 'solutionHide')
          const $solutionsButtons = $solutionsCards.find(Selector.SOLUTIONS_CARDS_BUTTON)
          $solutionsButtons.click(function (e) {
            e.preventDefault()
            e.stopPropagation()
            Oneci._jQueryInterface.call($(this), 'solutionOpen')
          })
        }
      }
      /**
       * ------------------------------------------------------------------------
       * Internal Career tabs
       * ------------------------------------------------------------------------
       */
      const $careerNav = $(Selector.CAREER_NAV)
      const $careerSelect = $(Selector.CAREER_SELECT)
      if ($careerNav.length > 0 && $careerSelect.length > 0) {
        const isNavVisible = $careerNav.css('display') !== 'none'
        const isSelectVisible = $careerSelect.css('display') !== 'none'
        if (!isNavVisible && isSelectVisible) {
          const $items = $careerNav.find(Selector.CAREER_SWITCH)
          const data = []
          $items.each((i, item) => {
            const $item = $(item)
            data.push({
              id: $item.attr('href'),
              text: $item.html(),
              selected: i === 0
            })
          })
          if (data.length > 0) {
            const $select2 = $careerSelect.children(Selector.CAREER_SELECT2)
            $select2.select2({
              data,
              width: '100%',
              minimumResultsForSearch: Infinity
            })
              .on('select2:select', (e) => {
                const data = e.params.data
                const link = data.id
                if (link.length > 0) {
                  $items.filter(`a[href='${link}']`).tab('show')
                }
              })
          }
        }
      }
      const $tab = $(Selector.CAREER_TAB)
      if ($tab.length > 0) {
        const $tabLink = $tab.find(Selector.CAREER_TAB_LINK)
        const $vacancies = $(Selector.CAREER_TAB_VACANCIES)
        const $tabSwitch = $(Selector.CAREER_SWITCH)
        const $hidenBlocks = $('[data-hide]')
        $tabSwitch.click(function () {
          const $this = $(this)
          $hidenBlocks.each(function () {
            const $that = $(this)
            if ($that.data('hide') === true) {
              $that.hide()
            } else {
              $that.show()
            }
            if ($this.hasClass('vacancies')) {
              if ($that.data('hide') === true) {
                $that.show()
              } else {
                $that.hide()
              }
            }
          })
        })
        $tabLink.click(function (e) {
          e.preventDefault()
          const $this = $(this)
          const id = $this.data('content')
          const $activeTab = $tab.find('.internal').filter(`[data-content="${id}"]`)
          const $activeTabBackLink = $activeTab.find('.internal__buttonblock')
          if ($this.data('content')) {
            $activeTab.show()
            $vacancies.hide()
            $activeTabBackLink.click((e) => {
              e.preventDefault()
              $activeTab.hide()
              $vacancies.show()
            })
          }
        })
      }
      /**
       * ------------------------------------------------------------------------
       * Event form submit
       * ------------------------------------------------------------------------
       */
      const $eventForm = $(Selector.EVENT_FORM)
      if ($eventForm.length > 0) {
        const $eventFormAjax = $eventForm.find('.form-ajax')
        const $eventFormFirst = $eventForm.find('.first__step')
        const $eventFormNext = $eventForm.find('.next__step')
        const $registerBtn = $(Selector.EVENT_FORM_REGISTER_BTN)
        $eventFormAjax.on('success', () => {
          $eventFormFirst.hide()
          $eventFormNext.show()
          $registerBtn.removeClass('d-sm-block d-md-none').addClass('d-none')
        })
      }
      /**
       * ------------------------------------------------------------------------
       * Event select2
       * ------------------------------------------------------------------------
       */
      // const $eventSelect = $('.js-event-select')
      // const $eventTabs = $('.nav-tabs .nav-link')
      // const event = []
      // $eventTabs.each(function () {
      //   const $this = $(this)
      //   event.push({
      //     text: $this.text()
      //   })
      // })
      // if (!Util.isDesktop()) {
      //   $eventSelect.select2({
      //     data: event,
      //     minimumResultsForSearch: Infinity,
      //     width: '100%',
      //     tags: true
      //   })
      // }
      /**
       * ------------------------------------------------------------------------
       * TradeMark fix
       * ------------------------------------------------------------------------
       */
      const $speakers = $('.event__form-speakers_wrapper')
      if ($speakers && Util.isMobile()) {
        $speakers.wrapAll('<div class="col-12 owl-carousel owl-theme event__slider" data-margin="30" data-responsive="event"></div>')
      }
      /**
       * ------------------------------------------------------------------------
       * TradeMark fix
       * ------------------------------------------------------------------------
       */
      const $h3 = $(Selector.PARTNERS_CATALOGUE_H3)
      if ($h3.length > 0) {
        $h3.each(function () {
          const $this = $(this)
          $this.html($this.html()
            .replace(/[®]/g, '<sup>$&</sup>'))
        })
      }
      /**
       * ------------------------------------------------------------------------
       * Footer mobile menu
       * ------------------------------------------------------------------------
       */
      const $footerTitle = $(Selector.FOOTER_TITLE)
      const $footerWrappers = $(Selector.FOOTER_WRAPPER)
      const visible = $footerWrappers.css('display')
      if ($footerTitle.length > 0 && visible === 'none') {
        $footerTitle.click(function () {
          const $this = $(this)
          const id = $this.attr('data-id')
          $footerWrappers.hide()
          $footerTitle.removeClass(Classes.SHOW)
          if (id) {
            $this.toggleClass(Classes.SHOW)
            $footerWrappers.filter(`[data-parent-id=${id}]`)
              .toggle()
          }
        })
      }
      /**
       * ------------------------------------------------------------------------
       * Main Slider loaded bg
       * ------------------------------------------------------------------------
       */
      const $bgImage = $(Selector.MAIN_SLIDER_BG)
      const currentLocation = window.location.origin
      if ($bgImage.length > 0) {
        $bgImage.each(function () {
          const $this = $(this)
          const imageUrlSm = $this.data('bg-mobile')
          const imageUrlLg = $this.data('bg-desktop')
          const $shadowImage = new Image()
          if (Util.isDesktop()) {
            $shadowImage.src = `${currentLocation}${imageUrlLg}`
          } else {
            $shadowImage.src = `${currentLocation}${imageUrlSm}`
          }
          $shadowImage.onload = () => {
            $this.css('background-image', `url(${$shadowImage.src})`).addClass('visible__bg')
          }
        })
      }
      /**
       * ------------------------------------------------------------------------
       * Cookie Policy
       * ------------------------------------------------------------------------
       */
      const $cookieBlock = $(Selector.COOKIE_BLOCK)
      if ($cookieBlock.length > 0) {
        Oneci._jQueryInterface.call($cookieBlock, 'cookiePolicyShow')
        const $cookieClose = $(Selector.COOKIE_CLOSE)
        $cookieClose.click((e) => {
          e.preventDefault()
          e.stopPropagation()
          Oneci._jQueryInterface.call($cookieBlock, 'cookiePolicy')
        })
      }
      /**
       * ------------------------------------------------------------------------
       * NewsRoom more button
       * ------------------------------------------------------------------------
       */
      const $newsroomBlock = $(Selector.NEWSROOM_BLOCK)
      if ($newsroomBlock.length > 0 && !Util.isEditMode()) {
        Oneci._newsroomShowMoreBtn($newsroomBlock)
        const $newsroomClose = $newsroomBlock.find(Selector.NEWSROOM_MORE_BTN)
        $newsroomClose.click((e) => {
          e.preventDefault()
          e.stopPropagation()
          Oneci._newsroomShowMore($newsroomBlock)
        })
      }
    })

  /**
   * ------------------------------------------------------------------------
   * jQuery
   * ------------------------------------------------------------------------
   */

  $.fn[NAME] = Oneci._jQueryInterface
  $.fn[NAME].Constructor = Oneci
  $.fn[NAME].noConflict = function () {
    $.fn[NAME] = JQUERY_NO_CONFLICT
    return Oneci._jQueryInterface
  }

  return Oneci
})($)

export default Oneci
